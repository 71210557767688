import { cliente } from './../../modelo/cliente.model';
import { clienteServicio } from './../../servicios/cliente.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.scss']
})
export class ClientesComponent implements OnInit {
  clientes: cliente[];

  // tslint:disable-next-line: no-shadowed-variable
  constructor(private clienteServicio: clienteServicio) { }

  ngOnInit() {
    this.clienteServicio.getClientes().subscribe(
      clientes => {
         this.clientes = clientes;
      }
    );

  }

}
