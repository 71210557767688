import { clienteServicio } from './../servicios/cliente.service';
import { cliente } from './../modelo/cliente.model';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-listado',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.scss']
})
export class ListadoComponent implements OnInit {

 clientes: cliente[];

  // tslint:disable-next-line: no-shadowed-variable
  constructor( private clienteServicio: clienteServicio) { }

  ngOnInit() {
    this.clienteServicio.getClientes().subscribe(
      clientes => {
        this.clientes = clientes;
      }
    );
  }

}
