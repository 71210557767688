import { clienteServicio } from './servicios/cliente.service';
import { environment } from './../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AngularFireModule} from '@angular/fire';
import { AngularFirestoreModule, FirestoreSettingsToken} from '@angular/fire/firestore';
import { AngularFireAuthModule} from '@angular/fire/auth';
import {FlashMessagesModule} from 'angular2-flash-messages';
import {FormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FooterComponent } from './footer/footer.component';
import { NavegacionComponent } from './navegacion/navegacion.component';
import { HeaderComponent } from './header/header.component';
import { ContenidoComponent } from './contenido/contenido.component';
import { NoticiasComponent } from './noticias/noticias.component';
import { MetodoComponent } from './metodo/metodo.component';
import { from } from 'rxjs';
import { ReenvioComponent } from './reenvio/reenvio.component';
import { ClientesComponent } from './componentes/clientes/clientes.component';
import { LoginComponent } from './componentes/login/login.component';
import { RegistroComponent } from './componentes/registro/registro.component';
import { ListadoComponent } from './listado/listado.component';
import { LandingComponent } from './landing/landing.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavegacionComponent,
    HeaderComponent,
    ContenidoComponent,
    NoticiasComponent,
    MetodoComponent,
    ReenvioComponent,
    ClientesComponent,
    LoginComponent,
    RegistroComponent,
    ListadoComponent,
    LandingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firestore, 'larosca'),
    AngularFirestoreModule,
    AngularFireAuthModule,
    FormsModule,
    FlashMessagesModule.forRoot()
  ],
  providers: [clienteServicio],
  bootstrap: [AppComponent]
})
export class AppModule { }
