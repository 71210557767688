import { cliente } from './../modelo/cliente.model';
import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()

// tslint:disable-next-line: class-name
export class clienteServicio {
  clientesColeccion: AngularFirestoreCollection<cliente>;
  clienteDoc: AngularFirestoreDocument<cliente>;
  clientes: Observable<cliente[]>;
  cliente: Observable<cliente>;

  constructor(private db: AngularFirestore) {
    this.clientesColeccion = db.collection('clientes', ref => ref.orderBy('nombre', 'asc'));

  }
  getClientes(): Observable<cliente[]> {

    this.clientes = this.clientesColeccion.snapshotChanges().pipe(
      map(cambios => {
        return cambios.map(accion => {
          const datos = accion.payload.doc.data() as cliente;
          datos.id = accion.payload.doc.id;
          return datos;
        });
      })
    );
    return this.clientes;
  }
  // tslint:disable-next-line: no-shadowed-variable
  agregarCliente(cliente: cliente) {

    this.db.collection('clientes', ref => ref.where('email', '==', cliente.email))
      .snapshotChanges().subscribe(querySnapshot => {
        if (querySnapshot.length > 0) {
          return;
        } else {
          this.clientesColeccion.add(cliente);
        }
      });

  }
}
