import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { cliente } from './../modelo/cliente.model';
import { clienteServicio } from './../servicios/cliente.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-reenvio',
  templateUrl: './reenvio.component.html',
  styleUrls: ['./reenvio.component.scss']
})
export class ReenvioComponent implements OnInit {

  clientes: cliente[];
  cliente: cliente = {
    nombre: '',
    apellidoprimero: '',
    apellidosegundo: '',
    email: '',
  };
  @ViewChild('clienteForm', { static: true }) clienteForm: NgForm;
  @ViewChild('botonCerrar', {static: true}) botonCerrar: ElementRef;
  submited: boolean;





  constructor(private clientesServicio: clienteServicio,
              private flasMessages: FlashMessagesService
  ) { }

  ngOnInit() {
  }


  agregar({ value, valid }: { value: cliente, valid: boolean }) {
    if (!valid) {
      this.flasMessages.show('Por favor rellena todos los datos', {
        cssClass: 'alert-danger', timeout: 4000
      });
    } else {
      // agregar nuevo cliente
      this.clientesServicio.agregarCliente(value);
      this.clienteForm.resetForm();
      this.submited = true;
      // this.cerrarModal();

    }
  }
}
